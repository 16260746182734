import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        window.goal(link.dataset.goal);
      }
    }
  });
  return false;
});

window.onload = function () {
    //initialize swiper when document ready
    var main = document.querySelector('#main');
    var slideshow = document.querySelector('#slideshow');

    // if (main.classList.contains(".cabinet:not(.transaction)")) {



    // }

    if (slideshow != null) {
        function addslider() {
            var slider = document.querySelector('#slideshow');
            var siderItems = slider.querySelector('ul.uk-grid');
            if (window.outerWidth <= 767) {
                // slider.setAttribute('slideshow','');
                siderItems.classList.add('uk-slideshow-items');
                UIkit.slideshow(slider);
            } else {
                slider.removeAttribute('slideshow');
                siderItems.classList.remove('uk-slideshow-items');
                UIkit.grid(siderItems);
            }
        }
        window.addEventListener('resize', function () {
            addslider();

        });
        addslider();

    }


};

if(window.jQuery){
    jQuery(document).ready(function ($) {



        var swiper = new Swiper('.membersIndex', {
            slidesPerView: 4,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="active ' + currentClass + '"></span>' +
                            ' <span class="liner"></span> ' +
                            '<span class="' + totalClass + '"></span>';
                },
            },

            breakpoints: {
                320: {
                  slidesPerView: 1,
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                  },
                },
                768: {
                  slidesPerView: 2,
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                  },
                },
                1920: {
                  slidesPerView: 4,
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                  },
                },
              }
        });

        $(".panel-scrollable").mCustomScrollbar({
          theme: "dark-thick",
      });
      
    });
}