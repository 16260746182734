require('../scss/app.scss');
require('../fonts/Lato/stylesheet.css');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/jquery.min.js');
require('./common/swiper.js');
require('./common/jquery.mCustomScrollbar.concat.min');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/uikit.js');
require('./common/uikit-icons.js');
require('./common/input_mask.js');

import "@fortawesome/fontawesome-free/js/all";
import "jquery.maskedinput/src/jquery.maskedinput.js";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";

